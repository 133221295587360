// import './App.css';
import { Toaster } from "react-hot-toast";
import Support from "./pages/support/support";
import "./styles.scss";
import { useEffect, useRef } from 'react';
import { useLocation } from "react-router-dom";

const App = () => {
  const messagesEndRef: any = useRef(null);
  const location = useLocation();
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      console.log(element);
      console.log(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [])

  return (
    <>
      <div className="App">
        <div className="lightblue-bg py-5" id="home">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-lg-6">
                <p className="fs-18 fw-700 m-0">Simplifying Subscription Plans</p>
                <h4 className="fs-32 fw-700 mb-3" style={{lineHeight: '150%'}}>
                  FinanceMutual App empowers you to design flexible subscription
                  plans, making your services more accessible and tailored to your
                  customers' needs.
                </h4>
                <button className="btn btn-primary" onClick={() => scrollToBottom()}>Contact Us</button>
              </div>
              <div className="col-12 col-lg-6">
                <img src="/assets/images/person-1.png" alt="" className="-roundImgBG" />
              </div>
            </div>
          </div>
        </div>

        <section className="py-5" id="whatMakesUsDifferent">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-5">
                <img src="/assets/images/person-2.png" alt="" />
              </div>

              <div className="col-12 col-lg-7">
                <h4 className="fs-32 fw-700 m-0 mb-1">
                  What Makes Us Different
                </h4>
                <p className="fs-16 fw-500">
                  We are partner in your financial journey. Here’s what sets us apart:
                </p>
                <ul className="mt-3">
                  <li className="d-flex mb-3">
                    <span style={{background: '#12a9f6'}} className="me-2 rounded-circle fs-16 fw-600 hw-24 d-flex align-items-center justify-content-center text-white">1</span>
                    <span>
                      <p className="fs-20 fw-700 text-lightblue">User-Friendly Experience</p>
                      <p className="fs-18 fw-400" style={{color: 'black'}}>We offer intuitive tools designed to make money management simple and accessible.</p>
                    </span>
                  </li>
                  <li className="d-flex mb-3">
                    <span style={{background: '#12a9f6'}} className="me-2 rounded-circle fs-16 fw-600 hw-24 d-flex align-items-center justify-content-center text-white">2</span>
                    <span>
                      <p className="m-0 fs-20 fw-700 text-lightblue">Transparent and Trustworthy</p>
                      <p className="m-0 fs-18 fw-400" style={{color: 'black'}}>No hidden fees or confusing terms—just clear, honest communication.</p>
                    </span>
                  </li>
                  <li className="d-flex mb-3">
                    <span style={{background: '#12a9f6'}} className="me-2 rounded-circle fs-16 fw-600 hw-24 d-flex align-items-center justify-content-center text-white">3</span>
                    <span>
                      <p className="m-0 fs-20 fw-700 text-lightblue">Personalized Solutions</p>
                      <p className="m-0 fs-18 fw-400" style={{color: 'black'}}>Our services are customized to align with your specific needs and objectives. We understand what matters most to you.</p>
                    </span>
                  </li>
                  <li className="d-flex mb-3">
                    <span style={{background: '#12a9f6'}} className="me-2 rounded-circle fs-16 fw-600 hw-24 d-flex align-items-center justify-content-center text-white">4</span>
                    <span>
                      <p className="m-0 fs-20 fw-700 text-lightblue">Cutting-Edge Security</p>
                      <p className="m-0 fs-18 fw-400" style={{color: 'black'}}>We prioritize your privacy with top-tier encryption and data protection.</p>
                    </span>
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </section>

        <section className="py-5" id="whyChooseUs">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-7">
                <h4 className="fs-32 fw-700 m-0 mb-1">
                  Why Choose Us?
                </h4>
                <p className="fs-16 fw-500">
                  At FinanceMutual App, we help you deliver personalized, flexible solutions that drive customer satisfaction and loyalty.
                </p>
                <ul className="mt-3">
                  <li className="d-flex mb-3" >
                    <span style={{background: '#12a9f6'}} className="me-2 rounded-circle fs-16 fw-600 hw-24 d-flex align-items-center justify-content-center text-white">1</span>
                    <span>
                      <p className="fs-20 fw-700 text-lightblue">Comprehensive Solutions</p>
                      <p className="fs-18 fw-400" style={{color: 'black'}}>We provide all-in-one services to cover every aspect of your money management needs.</p>
                    </span>
                  </li>
                  <li className="d-flex mb-3" >
                    <span style={{background: '#12a9f6'}} className="me-2 rounded-circle fs-16 fw-600 hw-24 d-flex align-items-center justify-content-center text-white">2</span>
                    <span>
                      <p className="m-0 fs-20 fw-700 text-lightblue">Expert Support</p>
                      <p className="m-0 fs-18 fw-400" style={{color: 'black'}}>Our team of experienced professionals is dedicated to guiding you every step of the way.</p>
                    </span>
                  </li>
                  <li className="d-flex mb-3" >
                    <span style={{background: '#12a9f6'}} className="me-2 rounded-circle fs-16 fw-600 hw-24 d-flex align-items-center justify-content-center text-white">3</span>
                    <span>
                      <p className="m-0 fs-20 fw-700 text-lightblue">Innovation at Its Core</p>
                      <p className="m-0 fs-18 fw-400" style={{color: 'black'}}>We continuously evolve our platform to ensure you benefit from the latest technology and tools.</p>
                    </span>
                  </li>
                  <li className="d-flex mb-3" >
                    <span style={{background: '#12a9f6'}} className="me-2 rounded-circle fs-16 fw-600 hw-24 d-flex align-items-center justify-content-center text-white">4</span>
                    <span>
                      <p className="m-0 fs-20 fw-700 text-lightblue">Client-Centered Approach</p>
                      <p className="m-0 fs-18 fw-400" style={{color: 'black'}}>Your satisfaction and success are our top priorities, and we’re committed to helping you achieve your goals.</p>
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-lg-5">
                <img src="/assets/images/person-3.png" alt="" />
              </div>
            </div>
          </div>
        </section>

        <section className="lightblue-bg py-5" id="features">
          <div className="container">
            <p className="fs-18 fw-700 m-0 pb-3" style={{color: '#667085'}}>Your Success Drives Us</p>
            <h4 className="fs-32 fw-700 m-0 mb-1 pb-3" style={{lineHeight: '150%'}}>
              Unlike other tools that only create subscription plans, FinanceMutual App goes further by helping you create, manage, and collect payments, ensuring a seamless experience for both you and your clients.
            </h4>
            <div className="row ">
              <div className="col-12 col-lg-6 col-xl-4 mb-3">
                <div className="p-3">
                  <img src="/assets/images/wallet-check.png" alt="" className="mb-4" />
                  <h4 className="fs-24 fw-700 mb-3">
                    Make Services Affordable
                  </h4>
                  <p className="fs-18 fw-400 mb-4" style={{color: 'black'}}>
                    By offering flexible subscription plans, you can cater to a wider audience, making your services more accessible and appealing. This approach also allows customers to choose the plan that best fits their needs, enhancing their overall experience.
                  </p>
                  <span className="fs-16 fw-400 m-0" style={{color: '#7E7E7E'}}>
                    FinanceMutual App gives you the tools to tailor options that fit your customers' requirements, ensuring a personalized experience that drives customer satisfaction and loyalty. With our platform, you can adapt quickly to market changes and stay ahead of the competition.
                  </span>
                </div>
              </div>

              <div className="col-12 col-lg-6 col-xl-4 mb-3">
                <div className="p-3">
                  <img src="/assets/images/wallet-money.png" alt="" className="mb-4" />
                  <h4 className="fs-24 fw-700 mb-3">
                    Simplified Payment Management
                  </h4>
                  <p className="fs-18 fw-400 mb-4" style={{color: 'black'}}>
                    Managing payments has never been easier. With FinanceMutual App, you can oversee all your transactions in one place, reducing the complexity of handling multiple payment streams.
                  </p>
                  <span className="fs-16 fw-400 m-0" style={{color: '#7E7E7E'}}>
                    You can set up reminders, track payment histories, and even manage late fees—all from a single dashboard. This efficiency frees up your time to focus on growing your business, while FinanceMutual App handles the nitty-gritty of payment management.
                  </span>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl-4 mb-3">
                <div className="p-3">
                  <img src="/assets/images/scroll.png" alt="" className="mb-4" />
                  <h4 className="fs-24 fw-700 mb-3">
                    Effortless Website Integration
                  </h4>
                  <p className="fs-18 fw-400 mb-4" style={{color: 'black'}}>
                    Easily integrate FinanceMutual App's user-friendly APIs into your website, enabling you to offer subscription plans directly from your platform.
                  </p>
                  <span className="fs-16 fw-400 m-0" style={{color: '#7E7E7E'}}>
                    Our APIs are designed to be straightforward and user-friendly, enabling you to quickly embed subscription plan options directly onto your platform. Whether you’re running an e-commerce site, a service portal, or any other online business, FinanceMutual App's integration process is tailored to fit smoothly within your existing infrastructure.
                  </span>
                </div>
              </div>
            </div>
            <button onClick={() => scrollToBottom()} className="btn btn-primary">
              Request a Callback
            </button>
          </div>
        </section>

        <section className="section--cta ">
          <div className="cta--container" style={{ backgroundImage: `url('/assets/images/cta-bg.png')` }}>
            <div className="cta--left">
              <h6>Take Action Now or Miss Out</h6>
              <h2 style={{lineHeight: '150%'}} className="mb-4">Offering easy subscription options makes your customers 70% more likely to purchase your services.</h2>
              <button className="btn btn-primary" onClick={() => scrollToBottom()}>Schedule a Demo</button>
            </div>
            <div className="cta--right">
              <img src="/assets/images/cta_img.png" alt="" />
            </div>
          </div>
        </section>

        {/* <section className="pricing py-5" id="pricing">
          <div className="container">
            <div className="row">
              <h3 className="heading text-center">Recuvery App Pricing</h3>
              <div className="col-lg-4 col-12">
                <div className="box-outer">
                  <div className="price-header orange">
                    <p className="price-card-title">Basic</p>
                    <p className="test">For Freelancers and small businesses just starting out.</p>
                  </div>
                  <div className="inner">
                    <div className="price-amount">$50<span className="month">/mo*</span></div>
                    <p className="pricing-blurb">Up to 50 invoices per month</p>
                    <div className="features">
                      <p className="includes">Includes:</p>
                      <ul>
                        <li>Access to basic invoice templates.</li>
                        <li>Multi-currency support.</li>
                        <li>Email-based customer support.</li>
                        <li>Secure cloud storage for invoices and client data.</li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="box-outer green-outer">
                  <div className="price-header green">
                    <p className="price-card-title">Professional</p>
                    <p className="text">For Growing businesses that need advanced invoicing.</p>
                  </div>
                  <div className="inner">
                    <div className="price-amount">$100<span className="month">/mo*</span></div>
                    <p className="pricing-blurb">Unlimited invoices</p>
                    <div className="features">
                      <p className="includes">Everything in Basic, and:</p>
                      <ul>
                        <li>Access to premium and customizable templates.</li>
                        <li>Multi-language support for global clients.</li>
                        <li>Priority email and chat support.</li>
                        <li>Analytics, Automated payment reminders</li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="box-outer">
                  <div className="price-header purple">
                    <p className="price-card-title">Enterprise</p>
                    <p className="text">For Established businesses and enterprises.</p>
                  </div>
                  <div className="inner">
                    <div className="price-amount">$200<span className="month">/mo*</span></div>
                    <p className="pricing-blurb">Unlimited invoices</p>
                    <div className="features">
                      <p className="includes">Everything in Professional, and:</p>
                      <ul>
                        <li>Team collaboration with role-based permissions.</li>
                        <li>Custom branding</li>
                        <li>API access for seamless integration.</li>
                        <li>Dedicated account manager, 24/7 support.</li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 text-center">
                <p className="note">*Prices are listed in USD and may vary by local currency.</p>
                <a href="#about-us" className="primary_button">Start Your Free Trial Now</a>
              </div>
            </div>
          </div>
        </section> */}
        
        <div ref={messagesEndRef} id="contact">
          <Support />

        </div>
        {/* <Footer /> */}
      </div>
      <Toaster position="top-right" />
    </>
  );
};

export default App;
