import { FC, useState } from 'react';
import './header.scss';
interface headerProps {}

const Header: FC<headerProps> = () => {
  const [origin, setData] = useState(window.location.origin);
  return (
    <>
      <header className="header-main p-3 py-3 position-sticky top-0 w-100" id="header">
        <div className="container d-flex justify-content-between align-items-center">
            <div className='d-flex'>
              <img src='/assets/images/logo.svg' alt='' style={{'width': '25px', 'marginRight': '10px'}}/>
              <a className='text-white m-0 fs-20 fw-800' href='/'>FinanceMutual<span className='text-lightblue'>App</span></a>
            </div>
            <ul className="d-flex align-items-center header-menus">

              <li className="me-3"><a className="fs-14 fw-700 " href={origin + '#home'}>Home</a></li>
              <li className="me-3"><a className="fs-14 fw-700 " href={origin + '#whatMakesUsDifferent'}>What makes us different</a></li>
              <li className="me-3"><a className="fs-14 fw-700 " href={origin + '#whyChooseUs'}>Why choose us</a></li>
              <li className="me-3"><a className="fs-14 fw-700 " href={origin + '#features'}>Features</a></li>
              {/* <li className="me-3"><a className="fs-14 fw-700 " href={origin + '#pricing'}>Pricing</a></li> */}
              <li className="me-3"><a className="fs-14 fw-700 " href={origin + '#contact'}>Contact</a></li>
            {/* <li className="me-3"><a className="fs-14 fw-700 " href="/terms-conditions">Terms</a></li>
            <li className="me-3"><a className="fs-14 fw-700 " href="/privacy-policy">Privacy Policy</a></li>
            <li className="me-3"><a className="fs-14 fw-700 " href="/support">Support</a></li> */}
            </ul>
        </div>
     </header>
    </>
  )
}

export default Header;
